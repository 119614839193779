// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getGroup: '',
    getGroupChats: '',
    getGroupById: '',
    getIndividualChats: '',
    getAllChatUsers: '',
}

const mutations = {
    SET_GROUP_DATA(state, data) {
        state.getGroup = data
    },
    SET_GROUP_CHAT_BY_ID(state, data) {
        state.getGroupChats = data
    },

    SET_GROUP_BY_ID(state, data) {
        state.getGroupById = data
    },
    SET_INDIVIDUAL_CHAT(state, data) {
        state.getIndividualChats= data
    },
    SET_ALL_CHAT_USER_CHAT(state, data) {
        state.getAllChatUsers= data
    },
}

const actions = {
    // Additional actions if needed
      getGroups({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_GROUPS_BY_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getGroups---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_GROUP_DATA', res.data.data)
                        }
                        else{
                           commit('SET_GROUP_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getGroups err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getGroupChats({ commit }, groupId) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_GROUP_CHATS_BY_ID + groupId, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getGroupChats---res !!', res)
                    if(res.data.data){
                        commit('SET_GROUP_CHAT_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_GROUP_CHAT_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getGroupChats err', err)
                })
        // })
    },

    addGroup({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("groupName", payload.groupName);
        formData.append("groupLogo", payload.groupLogo);
        formData.append("adminId", payload.adminId);
        formData.append("group_ownerId", payload.group_ownerId);
        formData.append("members", "");
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.CREATE_GROUP, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addGroup res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getGroups', payload.adminId)
                })
                .catch((err) => {
                    console.log("addGroup err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },
    
    deleteGroup({ dispatch }, payload) {
        console.log("payload--", payload);
    
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_GROUP + payload.groupId, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("deleteGroup res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getGroups', payload.adminId)

                    // router.push("/messaging")
                })
                .catch((err) => {
                    console.log("deleteGroup err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },
    
    


    joinGroup({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("groupId", payload.groupId);
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.JOIN_GROUP, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("joinGroup res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                })
                .catch((err) => {
                    console.log("joinGroup err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    leaveGroup({dispatch}, payload) {
        console.log("payload--", payload);

        var formData = new FormData();
        formData.append("groupId", payload.groupId);
        formData.append("userId", payload.userId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.LEAVE_GROUP, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log(" res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                })
                .catch((err) => {
                    console.log(" err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    sendMessage({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("groupId", payload.groupId);
        formData.append("userId", payload.userId);
        formData.append("message", payload.message);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.SEND_MESSAGE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("sendMessage res", res);
                    resolve(res);
                    // dispatch("checkErrorAndSendToast", [
                    //     res.data.message,
                    //     "success",
                    // ]);
                    // router.push("/my-enquiries")
                })
                .catch((err) => {
                    console.log("sendMessage err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },


    deleteUnitById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_UNIT_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteUnitById---res !!', res)
                    resolve(res)
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getUnit', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteUnitById err', err)
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                })
        })
    },

    getGroupById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_GROUP_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getGroupById---res !!', res)
                    if(res.data.data){
                        commit('SET_GROUP_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_GROUP_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getGroupById err', err)
                })
        // })
    },

    editGroup({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("groupName", payload.groupName);
        formData.append("groupLogo", payload.groupLogo);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.EDIT_GROUP_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editGroup res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getGroups', payload.adminId)
                })
                .catch((err) => {
                    console.log("editGroup err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

    getIndividualChats({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_MESSAGE_INDIVIDUAL + payload.sender + '/' + payload.receiver, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getIndividualChats---res !!', res)
                    if(res.data.data){
                        commit('SET_INDIVIDUAL_CHAT', res.data.data)
                    }
                    else{
                       commit('SET_INDIVIDUAL_CHAT', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getIndividualChats err', err)
                })
        // })
    },

    getAllChatUser({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_ALL_CHAT_USERS + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getAllChatUser---res !!', res)
                    if(res.data.data){
                        commit('SET_ALL_CHAT_USER_CHAT', res.data.data)
                    }
                    else{
                       commit('SET_ALL_CHAT_USER_CHAT', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getAllChatUser err', err)
                })
        // })
    },

    sendMessageIndividual({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("senderId", payload.senderId);
        formData.append("receiverId", payload.receiverId);
        formData.append("message", payload.message);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.SEND_MESSAGE_INDIVIDUAL, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("sendMessageIndividual res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                })
                .catch((err) => {
                    console.log("sendMessageIndividual err", err);
                    reject(err);
                    dispatch("checkErrorAndSendToast", [
                        err.response,
                        "error",
                    ]);
                });
        });
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };