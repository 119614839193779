import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

axios.defaults.baseURL = "https://stratabackend.dotlinkertech.com/api/" 
// axios.defaults.baseURL = "https://6032-2401-4900-3eeb-6ad9-6cfa-e5ab-dc59-af09.ngrok-free.app/api/" 
// axios.defaults.baseURL = "http://localhost:50012/api/" 

createApp(App).use(store).use(router).mount('#app')

 

 

  
            
  