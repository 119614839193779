// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getUpcomingMeetings: '',
    getPastMeetings: '',
    meetingById: '',
    getAllActiveUsers: '',
    getAgendaItems: '',
    getAgendaItemById: '',
    getAgendaPDF: '',
    getMinutesPDF: '',
    getTopic: '',
    getActions: '',
    getActionById: '',
    getMeetingResponses: '',
    getComments: '',
}

const mutations = {
    SET_UPCOMING_MEETING_DATA(state, data) {
        state.getUpcomingMeetings = data
    },
    SET_PAST_MEETING_DATA(state, data) {
        state.getPastMeetings = data
    },
    SET_MEETING_BY_ID(state, data) {
        state.meetingById = data
    },
    SET_ALL_ACTIVE_USER_DATA(state, data) {
        state.getAllActiveUsers = data
    },
    SET_AGENDA_ITEMS_DATA(state, data) {
        state.getAgendaItems = data
    },
    SET_AGENDA_ITEM_BY_ID(state, data) {
        state.getAgendaItemById = data
    },
    SET_AGENDA_PDF(state, data) {
        state.getAgendaPDF = data
    },
    SET_MINUTES_PDF(state, data) {
        state.getMinutesPDF = data
    },
    SET_TOPICS_DATA(state, data) {
        state.getTopic = data
    },
    SET_ACTION_DATA(state, data) {
        state.getActions = data
    },
    SET_ACTION_BY_ID_DATA(state, data) {
        state.getActionById = data
    },
    SET_MEETING_RESPONSES(state, data) {
        state.getMeetingResponses = data
    },
    SET_COMMENT_DATA(state, data) {
        state.getComments = data
    },
}

const actions = {
    // Additional actions if needed
    getUpcomingMeetings({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_UPCOMING_MEETINGS + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getUpcomingMeetings---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_UPCOMING_MEETING_DATA', res.data.data)
                        }
                        else{
                           commit('SET_UPCOMING_MEETING_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getUpcomingMeetings err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getPastMeetings({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_PAST_MEETINGS + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getPastMeetings---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_PAST_MEETING_DATA', res.data.data)
                        }
                        else{
                           commit('SET_PAST_MEETING_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getPastMeetings err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getMeetingById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_MEETING_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getMeetingsById---res !!', res)
                    if(res.data.data){
                        commit('SET_MEETING_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_MEETING_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getMeetingsById err', err)
                })
        // })
    },

    addMeeting({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("topic", payload.topic.topic);
        formData.append("date", payload.date);
        formData.append("time_from", payload.time_from);
        formData.append("time_to", payload.time_to);
        formData.append("location", payload.location);
        formData.append("created_by", payload.created_by);
        formData.append("description", payload.description);
        formData.append("attachment", payload.attachment);
        formData.append("adminId", payload.adminId);
        formData.append("agendaId", payload.topic.agendaId);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.CREATE_MEETING, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addMeeting res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getUpcomingMeetings', payload.adminId)
                })
                .catch((err) => {
                    console.log("addMeeting err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    editMeeting({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("topic", payload.topic.topic);
        formData.append("date", payload.date);
        formData.append("time_from", payload.time_from);
        formData.append("time_to", payload.time_to);
        formData.append("location", payload.location);
        formData.append("created_by", payload.created_by);
        formData.append("description", payload.description);
        formData.append("attachment", payload.attachment);
        formData.append("adminId", payload.adminId);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.EDIT_MEETING_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editMeeting res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getUpcomingMeetings', payload.adminId)
                })
                .catch((err) => {
                    console.log("editMeeting err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },


    deleteMeetingById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_MEETING_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteMeetingById---res !!', res)
                    resolve(res)
                    dispatch('getUpcomingMeetings', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteMeetingById err', err)
                })
        })
    },

    getAllActiveUsers({ commit }) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_ALL_ACTIVE_USERS, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getAllActiveUsers---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_ALL_ACTIVE_USER_DATA', res.data.data)
                    }
                    else{
                       commit('SET_ALL_ACTIVE_USER_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getAllActiveUsers err', err)
                    // dispatch("checkErrorAndSendToast", [
                    //     err?.response,
                    //     "error",
                    //   ]);
                })
        // })
    },

    inviteMember({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        payload.userIds.forEach(userId => {
            formData.append("userIds[]", userId);
        });
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.INVITE_MEMBER_BY_MAIL + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("inviteMember res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('')
                })
                .catch((err) => {
                    console.log("inviteMember err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    respondToInvitation({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("response", payload.response);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.RESPOND_TO_INVITATION + `${payload.id}/respond/${payload.email}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("respondToInvitation res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getMeetingResponses', payload.id)
                })
                .catch((err) => {
                    console.log("respondToInvitation err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    getMeetingResponses({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_MEETING_RESPONSES + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getMeetingResponses---res !!', res)
                    if(res.data.data){
                        commit('SET_MEETING_RESPONSES', res.data.data)
                    }
                    else{
                       commit('SET_MEETING_RESPONSES', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getMeetingResponses err', err)
                })
        // })
    },

    addObjective({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("objective", payload.objective);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.ADD_AGENDA_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addObjective res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/all-enquiries")
                    dispatch('getMeetingById', payload.id)
                })
                .catch((err) => {
                    console.log("addObjective err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    getAgendaItems({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_AGENDA_ITEMS + `${payload.id}/agendas/${payload.agenda_id}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getAgendaItems---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_AGENDA_ITEMS_DATA', res.data.data)
                    }
                    else{
                       commit('SET_AGENDA_ITEMS_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getAgendaItems err', err)
                    // dispatch("checkErrorAndSendToast", [
                    //     err?.response,
                    //     "error",
                    //   ]);
                })
        // })
    },

    addAgenda({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("title", payload.title);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_AGENDA_ITEMS +  `${payload.id}/agendas-items/${payload.agenda_id}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addAgenda res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("addAgenda err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    editAgenda({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("title", payload.title);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.EDIT_AGENDA_ITEMS + `${payload.id}/agendas/${payload.agenda_id}/items/${payload.agendaItemId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editAgenda res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("editAgenda err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    getAgendaItemById({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_AGENDA_ITEM_BY_ID + `${payload.id}/agendas/${payload.agenda_id}/item/${payload.agendaItemId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getAgendaItemById---res !!', res)
                    if(res.data.data){
                        commit('SET_AGENDA_ITEM_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_AGENDA_ITEM_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getAgendaItemById err', err)
                })
        // })
    },

    addAttachment({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("attachment", payload.attachment);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_AGENDA_ITEM_ATTACHMENT +  `${payload.id}/agendas/${payload.agenda_id}/items/attachment/${payload.agendaItemId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addAttachment res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("addAttachment err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    
    deleteAgendaById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_AGENDA_ITEM + `${payload.id}/agendas/${payload.agenda_id}/items/${payload.agendaItemId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteAgendaById---res !!', res)
                    resolve(res)
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteAgendaById err', err)
                })
        })
    },

    getAgendaPDF({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.PDF_MEETING_AGENDA + `${payload.id}/agendas/pdf/${payload.agenda_id}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getAgendaPDF---res !!', res)
                    if(res.data.data){
                        commit('SET_AGENDA_PDF', res.data.data)
                    }
                    else{
                       commit('SET_AGENDA_PDF', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getAgendaPDF err', err)
                })
        // })
    },
    getMinutesPDF({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.PDF_MEETING_MINUTES + `${payload.id}/agendas/minutes/pdf/${payload.agenda_id}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getMinutesPDF---res !!', res)
                    if(res.data.data){
                        commit('SET_MINUTES_PDF', res.data.data)
                    }
                    else{
                       commit('SET_MINUTES_PDF', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getMinutesPDF err', err)
                })
        // })
    },

    getTopics({ commit }) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_TOPICS, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getTopics---res !!', res)
                    if(Array.isArray(res.data)){
                        commit('SET_TOPICS_DATA', res.data)
                    }
                    else{
                       commit('SET_TOPICS_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getTopics err', err)
                })
        // })
    },

    getAction({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_ACTION + `${payload.id}/agendas/${payload.agenda_id}/items/actions/${payload.agendaItemId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getAction---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_ACTION_DATA', res.data.data)
                    }
                    else{
                       commit('SET_ACTION_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getAction err', err)
                })
        // })
    },

    getActionById({ commit }, payload) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_ACTION_BY_ID + `${payload.id}/agendas/${payload.agenda_id}/item/${payload.agendaItemId}/action/${payload.actionId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getActionById---res !!', res)
                    if((res.data.data)){
                        commit('SET_ACTION_BY_ID_DATA', res.data.data)
                    }
                    else{
                       commit('SET_ACTION_BY_ID_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getActionById err', err)
                })
        // })
    },

    addUpdateMinutes({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("minutes", payload.minutes);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.ADD_UPDATE_MINUTES +  `${payload.id}/agendas/${payload.agenda_id}/items/minutes/${payload.agendaItemId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addUpdateMinutes res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("addUpdateMinutes err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    addAction({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("action", payload.action);
        formData.append("status", payload.status);
        payload.assigned_to.forEach(assignedUser => {
            formData.append("assigned_to[]", assignedUser);
        });
        formData.append("date", payload.date);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_ACTION +  `${payload.id}/agendas/${payload.agenda_id}/item/${payload.agendaItemId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addAction res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("addAction err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    updateAction({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("action", payload.action);
        formData.append("status", payload.status);
        payload.assigned_to.forEach(assignedUser => {
            formData.append("assigned_to[]", assignedUser);
        });
        formData.append("date", payload.date);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.ADD_ACTION +  `${payload.id}/agendas/${payload.agenda_id}/item/${payload.agendaItemId}/action/${payload.actionId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("updateAction res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("addAction err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    deleteActionById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_ACTION_BY_ID + `${payload.id}/agendas/${payload.agenda_id}/items/${payload.agendaItemId}/action/${payload.actionId}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteActionById---res !!', res)
                    resolve(res)
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteActionById err', err)
                })
        })
    },

    addCommentOnMeeting({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("comment", payload.comment);
        formData.append("email", payload.email);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_COMMENT + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addComment res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getCommentByMeetingId', payload.id)
                })
                .catch((err) => {
                    console.log("addComment err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    getCommentByMeetingId({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_COMMENT + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getCommentByMeetingId---res !!', res)
                    if((Array.isArray(res.data.data))){
                        commit('SET_COMMENT_DATA', res.data.data)
                    }
                    else{
                       commit('SET_COMMENT_DATA', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getCommentByMeetingId err', err)
                })
        // })
    },

    assignAndSaveAction({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("action", payload.action);
        formData.append("status", payload.status);
        payload.assigned_to.forEach(assignedUser => {
            formData.append("assigned_to[]", assignedUser);
        });
        formData.append("date", payload.date);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ASSIGN_AND_SAVE_ACTION + `${payload.id}/agendas/${payload.agenda_id}/item/asignAction/${payload.agendaItemId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("assignAction res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("assignAction err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    assignAndUpdateAction({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("action", payload.action);
        formData.append("status", payload.status);
        payload.assigned_to.forEach(assignedUser => {
            formData.append("assigned_to[]", assignedUser);
        });
        formData.append("isAssigned", true);
        formData.append("date", payload.date);
        
        return new Promise((resolve, reject) => {
            axios
                .patch(axios.defaults.baseURL + apis.ASSIGN_AND_UPDATE_ACTION_BY_ID + `${payload.id}/agendas/${payload.agenda_id}/item/${payload.agendaItemId}/action/assign/${payload.actionId}`, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("assignAndUpdateAction res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    dispatch('getAgendaItems', { id: payload.id, agenda_id: payload.agenda_id,})
                })
                .catch((err) => {
                    console.log("assignAndUpdateAction err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };