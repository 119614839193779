<template>
    <!-- header starts
============================================= -->
<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" style="padding: 40px 50px;">
    <div class="container-fluid">
    <!-- logo --> 
    <router-link class="logo-default dtr-scroll-link" to="/">
        <img src="assets/imagesLanding/new-logo.png" alt="logo">
        <!-- Strata Management -->
    </router-link> 
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-md-auto mb-2 mb-lg-0">
          <li class="nav-item"> <router-link class="nav-link" to="/">Home</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/pricing">Pricing</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/login">Login</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
  

    <!-- header ends
================================================== --> 
</template>

<script>
export default {
 name: "HeaderPage",
 props: {
   msg: String,
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../../public/assets/fontsLanding/iconfonts.css"></style>
<style scoped src="../../../public/assets/cssLanding/plugins.css"></style>
<style scoped src="../../../public/assets/cssLanding/style.css"></style>
<style scoped src="../../../public/assets/cssLanding/responsive.css"></style>
<style scoped src="../../../public/assets/cssLanding/color.css"></style>
<style scoped>
.nav-link{
    color: #1e247e !important;
    font-size: 17px;
}
</style>
